import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login-View.vue'
import Profile from '../views/Profile-View.vue'
import BusinessFocus from '../views/BusinessFocus-View.vue'
import AddFirstProject from '../views/AddFirstProject-View.vue'
import FocusAreasProjectOne from '../views/FocusAreasProjectOne-View.vue'
import AddSecondProject from '../views/AddSecondProject-View.vue'
import FocusAreasProjectTwo from '../views/FocusAreasProjectTwo-View.vue'
import AddAnotherProject from '../views/AddAnotherProject-View.vue'
import AddThirdProject from '../views/AddThirdProject-view.vue'
import FocusAreasProjectThree from '../views/FocusAreasProjectThree-View.vue'
import Completed from '../views/Completed-View.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Login
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/BusinessFocus',
    name: 'BusinessFocus',
    component: BusinessFocus
  },
  {
    path: '/AddFirstProject',
    name: 'AddFirstProject',
    component: AddFirstProject
  },
  {
    path: '/FocusAreasProjectOne',
    name: 'FocusAreasProjectOne',
    component: FocusAreasProjectOne
  },
  {
    path: '/AddSecondProject',
    name: 'AddSecondProject',
    component: AddSecondProject
  },
  {
    path: '/FocusAreasProjectTwo',
    name: 'FocusAreasProjectTwo',
    component: FocusAreasProjectTwo
  },
  {
    path: '/AddAnotherProject',
    name: 'AddAnotherProject',
    component: AddAnotherProject
  },
  {
    path: '/AddThirdProject',
    name: 'AddThirdProject',
    component: AddThirdProject
  },
  {
    path: '/FocusAreasProjectThree',
    name: 'FocusAreasProjectThree',
    component: FocusAreasProjectThree
  },
  {
    path: '/Completed',
    name: 'Completed',
    component: Completed
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // Delete the component: Completed line also
    // component: () => import(/* webpackChunkName: "about" */ '../views/Completed-View.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
