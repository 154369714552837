<script setup>
import Loading from '../components/LoadingComp.vue'
import ToastComponent from '../components/Toast-Component.vue' // Error messages
</script>

<script>
import config from '../config.json'
export default {
    name: 'SaveData',
    props: ['pageName'],
    data() {
        return {
            envConfig: config,
            loadingData: false,
            ErrorMessage: "There was an error saving your data please contact your GDS representative.",
            showErrorMessage: false,
            EPPID: ""
        }
    },

    mounted() {
        console.log(this.pageName);
        if(window.localStorage.getItem("SomeDataNotSent")== "true"){
            this.showErrorMessage = true;
            window.localStorage.setItem("SomeDataNotSent", false)
        }

    },
    methods: {
        updateProject() {



        },
        saveAllData() {
  
            // Project IDS
            let projectIDS = JSON.parse(window.localStorage.getItem("SFData"));
            let projectIDOne = projectIDS.Project1ID;
            let projectIDTwo = projectIDS.Project2ID;
            let projectIDThree = projectIDS.Project3ID;

            let profile = JSON.parse(window.localStorage.getItem("profileData"));
            let projectOneData = JSON.parse(window.localStorage.getItem("projectOneData"));
            let projectTwoData = JSON.parse(window.localStorage.getItem("projectTwoData"));
            let projectThreeData = JSON.parse(window.localStorage.getItem("projectThreeData"));
            // Main EPP ID
            let EPPID = JSON.parse(window.localStorage.getItem("EPPID"));
            this.EPPID = EPPID;
            
            this.loadingData = true;
            
            let endpointUrl = ''; // Set the localhost and development settings in config.json

            if (this.envConfig.environment === "production") {
                endpointUrl = this.envConfig.productionURL + "saveAllData"
            } if (this.envConfig.environment === "development") {
                endpointUrl = this.envConfig.developmentURL + "saveAllData"
            }
            if (this.envConfig.environment === "local") {
                endpointUrl = this.envConfig.localDev + "saveAllData"
            }

            fetch(endpointUrl, {
                method: 'POST', // Use POST method to send data
                body: JSON.stringify({
                    page: this.pageName,
                    EPPID: this.EPPID,
                    projectIDOne,
                    projectIDTwo,
                    projectIDThree,
                    profile: profile,
                    projectOneData: projectOneData,
                    projectTwoData: projectTwoData,
                    projectThreeData: projectThreeData
                }), // Convert input values to JSON format
                headers: {
                    'Content-Type': 'application/json' // Set request header to specify JSON format
                },
            }).then((response) => (response.json()))
                .then((body) => {
                    this.loadingData = false;
                    this.showErrorMessage = false;
                    if (body.dataSaved) {
                        console.log("data has been saved successfully");
                    }
                    if (!body.dataSaved) {
                        console.log("data has not been saved successfully");
                        window.localStorage.setItem("SomeDataNotSent", true)
                        this.showErrorMessage = true;
                    }
                }).catch(error => {
                    // Handle network or other error
                    this.showErrorMessage = true;
                    console.log(this.showErrorMessage);
                    console.log('Error occurred while sending login data: ', error);
                });
                //this.$router.push("Completed").catch(() => { });
            


        }
    }
}
</script>

<template>
    <div class="saveAllDataContainer">
        <Loading v-if="(this.loadingData)" />
        <ToastComponent class="movedLeft" :class="{ showErrorMessage, showErrorMessage }" :toastMessage="ErrorMessage" />

        <div v-if="pageName=='Project1'" class="twoColButtons">
            <router-link to="/profile">
                <div class="brandButton">Back</div>
            </router-link>
            <router-link to="/AddSecondProject">
                <div class="brandButtonLight" @click="saveAllData()">Save & Continue</div>
            </router-link>
        </div>

        <div v-if="pageName=='Project2'" class="twoColButtons">
            <router-link to="/AddFirstProject">
                <div class="brandButton">Back</div>
            </router-link>
            <router-link to="/AddAnotherProject">
                <div class="brandButtonLight" @click="saveAllData()">Save & Continue</div>
            </router-link>
        </div>

        <div v-if="pageName=='Project3'" class="twoColButtons">
            <router-link to="/AddSecondProject">
                <div class="brandButton">Back</div>
            </router-link>
            <router-link to="/Completed">
                <div class="brandButtonLight" @click="saveAllData()">Save & Continue</div>
            </router-link>
        </div>

        <div v-if="pageName=='SaveAddAnotherPage'" class="twoColButtons">
            <router-link to="/Completed">
                <div class="brandButtonLight" >Save & Finish</div>
            </router-link>
        </div>

    </div>
</template>

<style lang="scss" scoped>
  @import "../assets/sass/addProjects.scss";
</style>