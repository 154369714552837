<script setup>
    import "../assets/sass/focusAreas.scss"
    import HideUnselectedButtons from "../components/HideUnselectedButtons.vue"
</script>

<script>

export default {

    data() {

        return {
            lastSelectedButton: "",
            technology: false,
            data: false,
            culture: false,
            process: false,
            twoFocusAreasSelected: false,
            numberOfButtonsSelected: "",
            focusArea1: [],
            focusArea2: [],
            focusArea3: [],
            focusArea4: [],
            technologyTabSelected: false,
            dataTabSelected: false,
            cultureTabSelected: false,
            processTabSelected: false,
            ProjectOneData: "",
            currentPageName: "",
            techHeader: ".technology",
            dataHeader: ".data",
            cultureHeader: ".culture",
            processHeader: ".process"
        }
    },
    updated() {
        // Hide unselected technology buttons that were not chosen from BusinessFocus-View.vue  
        
        const businessFocusAreas = JSON.parse(window.localStorage.getItem("businessFocusAreas"));

        hideUnusedButtons(this.techHeader, businessFocusAreas.techValues)
        hideUnusedButtons(this.dataHeader, businessFocusAreas.dataValues)
        hideUnusedButtons(this.cultureHeader, businessFocusAreas.cultureValues)
        hideUnusedButtons(this.processHeader, businessFocusAreas.processValues)

        function hideUnusedButtons(tabs, storage){
            let technologyList = document.querySelectorAll(tabs + " .selectFocusAreas .subCategories")
            // Hide all
            technologyList.forEach(function (item) {
                item.classList.add("hidden")
            })

            // Remove hidden class for buttons that were selected from BusinessFocus-View.vue  
            storage.split(",").forEach(function (item) {
                technologyList.forEach(function (techItem) {
                    if (item.length > 0) {
                        if (item == techItem.textContent) {
                            techItem.classList.remove("hidden")
                        }
                    }
                })
            })
        }


    },
    // `mounted` is a lifecycle hook that can be called after the view has been rendered
    mounted() {

        setInterval(() => {
            this.numberOfButtonsSelected = document.querySelectorAll(".twoColFlex .active").length;
        }, 1000);
        // this.$nextTick(function () {
        //     let elements = document.querySelectorAll('.subCategories');
        //     let existingFocusAreas = JSON.parse(window.localStorage.getItem("projectOneData"));
        //     existingFocusAreas = existingFocusAreas.focusAreas;

        //     setTimeout(() => {

        //         existingFocusAreas.split(",").forEach((val) => {

        //             elements.forEach((el) => {
        //                 if(val.trim() === el.innerHTML.trim()){
        //                     console.log("yes");
        //                     el.classList.add("active")
        //                 }

        //             })
        //         })
        //     }, 1000)
        // })

        // Initialize project one data
        let projectThreeData = JSON.parse(window.localStorage.getItem("projectThreeData"));
        this.ProjectOneData = projectThreeData;

        // Get current page name to determine which project we are in
        let currentPageNameTemp = window.localStorage.getItem("currentViewName");
        this.currentPageName = currentPageNameTemp;

        window.scrollTo(0, 0);
        let serverData = JSON.parse(window.localStorage.getItem("SFData"));
        this.focusArea1 = serverData.FocusArea1.split(";");
        this.focusArea2 = serverData.FocusArea2.split(";");
        this.focusArea3 = serverData.FocusArea3.split(";");
        this.focusArea4 = serverData.FocusArea4.split(";");
        this.onlyShowTwoCols();
    },
    methods: {
        onlyShowTwoCols() {
            let twoColumsData = JSON.parse(window.localStorage.getItem("focusAreasSelected"));

            if (twoColumsData.technologyTabSelected === false) {
                document.querySelector(".singleColFlex.technology").style.display = "none"
                document.querySelector(".twoColFlex .technology").style.width = "100%"
            }
            if (twoColumsData.dataTabSelected === false) {
                document.querySelector(".singleColFlex.data").style.display = "none"
                document.querySelector(".twoColFlex .data").style.width = "100%"
            }
            if (twoColumsData.cultureTabSelected === false) {
                document.querySelector(".singleColFlex.culture").style.display = "none"
                document.querySelector(".twoColFlex .culture").style.width = "100%"
            }
            if (twoColumsData.processTabSelected === false) {
                document.querySelector(".singleColFlex.process").style.display = "none"
                document.querySelector(".twoColFlex .process").style.width = "100%"
            }

        },
        selectTwoButtonsOnly(event) {

            const myArray = [this.technology, this.data, this.culture, this.process];


            // First button has been selected
            if (this.countTrue(myArray) === 0) {
                this.twoFocusAreasSelected = false;
                if (event === "technology") {
                    this.technology = !this.technology;
                }
                if (event === "data") {
                    this.data = !this.data;
                }

                if (event === "culture") {
                    this.culture = !this.culture;
                }
                if (event === "process") {
                    this.process = !this.process;
                }

            }



            if (this.countTrue(myArray) === 1) {
                // Second button has been selected
                this.twoFocusAreasSelected = true;
                if (event === "technology") {
                    this.technology = !this.technology;
                }
                if (event === "data") {
                    this.data = !this.data;
                }

                if (event === "culture") {
                    this.culture = !this.culture;
                }
                if (event === "process") {
                    this.process = !this.process;
                }

            }

            // Second button has been selected, now does the selected button need to be toggled?
            if (this.countTrue(myArray) === 2) {
                if (event === "technology" & this.technology === true) {
                    this.technology = !this.technology;
                }
                if (event === "data" & this.data === true) {
                    this.data = !this.data;
                }
                if (event === "culture" & this.culture === true) {
                    this.culture = !this.culture;
                }
                if (event === "process" & this.process === true) {
                    this.process = !this.process;
                }
                if (this.countTrue() === 2 & event != this.lastSelectedButton) {
                    // console.log("Current button " + event);
                    // console.log("Last selected button " + this.lastSelectedButton);
                    if (event === "technology") {
                        this.technology = true;
                    }
                    if (event === "data") {
                        this.data = true;
                    }
                    if (event === "culture") {
                        this.culture = true;
                    }
                    if (event === "process") {
                        this.process = true;
                    }
                    if (this.lastSelectedButton === "technology") {
                        this.technology = !this.technology;
                    }
                    if (this.lastSelectedButton === "data") {
                        this.data = !this.data;
                    }
                    if (this.lastSelectedButton === "culture") {
                        this.culture = !this.culture;
                    }
                    if (this.lastSelectedButton === "process") {
                        this.process = !this.process;
                    }
                }
            }

            // If two buttons have been selected and the user tried to add a third then toggle the last selected button off
            // and enable the currently selected button 

            // Set the currently selected button as the "last selected button"
            this.lastSelectedButton = event;

            // This is a helper function that is used to check how many tabs have been selected as we only want 2 at the most to be selected

        },

        toggleSubCategoriesAndStore(event) {
            // Check if 15 nodes have been selected prevent any new ones being added
            if (document.querySelectorAll(".subCategories.active").length > 11) {
                if (event.target.classList.contains("active") & event.target.classList.contains("subCategories")) {
                    event.target.classList.remove("active")
                }
            } else {
                if (event.target.classList.contains("active") & event.target.classList.contains("subCategories")) {
                    event.target.classList.remove("active")
                } else {
                    event.target.classList.add("active")
                }

            }

            // Keep track of which tab has been selected and then store it in local storage(technology, data, culture, process)
            this.technologyTabSelected = document.querySelector(".singleColFlex.technology .brandButton.active ") !== null;
            this.dataTabSelected = document.querySelector(".singleColFlex.data .brandButton.active ") !== null;
            this.cultureTabSelected = document.querySelector(".singleColFlex.culture .brandButton.active ") !== null;
            this.processTabSelected = document.querySelector(".singleColFlex.process .brandButton.active ") !== null;
            let sectedTabsData = {
                "technologyTabSelected": this.technologyTabSelected,
                "dataTabSelected": this.dataTabSelected,
                "cultureTabSelected": this.cultureTabSelected,
                "processTabSelected": this.processTabSelected,
            }
            window.localStorage.setItem("FirstProjectFocusChoices", JSON.stringify(sectedTabsData));
        },
        goBackToProjectView(saveOrBack) {

            // TODO need to check if the correct number of values has been selected and then show the save and coninue button and back button
            this.ProjectOneData.focusAreasSelected = true;

            if (saveOrBack === "save") {
                this.ProjectOneData.technologyFocusAreas = this.getSelectedFromCol(".technology .selectFocusAreas .active")
                this.ProjectOneData.dataFocusAreas = this.getSelectedFromCol(".data .selectFocusAreas .active")
                this.ProjectOneData.cultureFocusAreas = this.getSelectedFromCol(".culture .selectFocusAreas .active")
                this.ProjectOneData.processFocusAreas = this.getSelectedFromCol(".process .selectFocusAreas .active")
                window.localStorage.setItem("projectThreeData", JSON.stringify(this.ProjectOneData));
                this.$router.push('/AddThirdProject').catch(() => { });
            }
            if (saveOrBack === "back") {
                this.$router.push('/AddThirdProject').catch(() => { });
            }






        },
        getSelectedFromCol(selector) {
            let itemsList = "";
            let focusAreasArray = document.querySelectorAll(selector)
            focusAreasArray.forEach(element => {
                itemsList = itemsList + element.innerHTML + ",";
            });
            return itemsList;
        },
        countTrue() {
            const myArray = [this.technology, this.data, this.culture, this.process];
            let count = 0;
            for (let i = 0; i < myArray.length; i++) {
                if (myArray[i]) {
                    count++;
                }
            }
            return count--;
        },
    },
    computed: {
        hasUserSelected() {
            return true
        }
    }
}
</script>



<template>
    <main class="focusAreasMain">
        <div class="focusAreasContainer">
            <h1>{{ ProjectOneData.projectName }}</h1>
            <h3>Please select up to 5 key focus areas that are related to this project.</h3>
            <div class="twoColFlex">
                <div class="singleColFlex technology ">
                    <div class="brandButton active">
                        Technology</div>
                    <div class="selectFocusAreas animateHeight">
                        <div v-for="(item) in focusArea1" class="subCategories displayRoman" :class="{ somethingElse: hasUserSelected }"
                            :key="item" @click="toggleSubCategoriesAndStore($event)">
                            {{ item }}  
                        </div>
                    </div>
                </div>

                <div class="singleColFlex data">
                    <div class="brandButton active">Data</div>
                    <div class="selectFocusAreas animateHeight">
                        <div v-for="(item) in focusArea2" class="subCategories displayRoman" :key="item"
                            @click="toggleSubCategoriesAndStore($event)">
                            {{ item }} 
                        </div>
                    </div>
                </div>

                <div class="singleColFlex culture">
                    <div class="brandButton active">Culture</div>
                    <div class="selectFocusAreas animateHeight">
                        <div v-for="(item) in focusArea3" class="subCategories displayRoman" :key="item"
                            @click="toggleSubCategoriesAndStore($event)">
                            {{ item }} 
                        </div>
                    </div>
                </div>

                <div class="singleColFlex process">
                    <div class="brandButton active">Process</div>
                    <div class="selectFocusAreas animateHeights">
                        <div v-for="(item) in focusArea4" class="subCategories displayRoman" :key="item"
                            @click="toggleSubCategoriesAndStore($event)">
                            {{ item }} 
                        </div>
                    </div>
                </div>


            </div>

            <div class="twoColButtons">
                <a @click="goBackToProjectView('back')">
                    <div class="brandButton">Back</div>
                </a>
                <a v-if="numberOfButtonsSelected > 4" @click="goBackToProjectView('save')">
                    <div class="brandButtonLight">Save & Continue</div>
                </a>
            </div>
            <HideUnselectedButtons />
        </div>


    </main>
</template>


<style lang="scss" scoped>

</style>