<script setup>
// import VerticalBread from '../components/VerticalBread.vue'
import Navigation from '../components/Navigation-Component.vue'
import Slider from '../components/Slider.vue'
import Loading from '../components/LoadingComp.vue'

</script>
<script>
import config from '../config.json'
export default {
  data() {

    return {
      envConfig: config,
      FirstName: "",
      LastName: "",
      Email: "",
      JobTitle: "",
      MobilePhone: "",
      CompanyName: "",
      EPPID: "",
      KeyStrategicObjectives: "",
      loadingData: false
    }
  },

  // `mounted` is a lifecycle hook which we will explain later
  mounted() {
    // TODO get these values from the server or from the user after they modify anything? 
    window.localStorage.setItem("currentViewName", "Profile")
    let serverData = JSON.parse(window.localStorage.getItem("SFData"));
    let EPPID = JSON.parse(window.localStorage.getItem("EPPID"));
    this.EPPID = EPPID;
    this.FirstName = serverData.FirstName;
    this.LastName = serverData.LastName;
    this.JobTitle = serverData.JobTitle;
    this.Email = serverData.Email;
    this.MobilePhone = serverData.MobilePhone;
    this.CompanyName = serverData.AccountName;
    this.KeyStrategicObjectives = serverData.KeyStrategicObjectives;



  },
  methods: {
    goHome() {
      this.$router.push("/").catch(() => { });
    },
    goToBusinessFocus() {

      let profileData = {
        EPPID: this.EPPID,
        keyObjectives: this.KeyStrategicObjectives,
        yearlySpend: window.localStorage.getItem("yearlySpend")
      }
      window.localStorage.setItem("profileData", JSON.stringify(profileData) )
      this.$router.push("BusinessFocus").catch(() => { });

      this.loadingData = true;
      let yearlySpend = window.localStorage.getItem("yearlySpend")

      // endpointUrl = '10.3.25.8:5333'; // Replace with the actual endpoint URL on server
      let endpointUrl = ''; // Replace with the actual endpoint URL on server

      if (this.envConfig.environment === "production") {
        endpointUrl = this.envConfig.productionURL + "profile"
      } if (this.envConfig.environment === "development") {
        endpointUrl = this.envConfig.developmentURL + "profile"
      }
      if (this.envConfig.environment === "local") {
        endpointUrl = this.envConfig.localDev + "profile"
      }

      fetch(endpointUrl, {
        method: 'POST', // Use POST method to send data
        body: JSON.stringify({ 
          EPPID: this.EPPID, 
          yearlySpend: yearlySpend,
          KeyStrategicObjectives: this.KeyStrategicObjectives
         }), // Convert input values to JSON format
        headers: {
          'Content-Type': 'application/json' // Set request header to specify JSON format
        },
        // signal: AbortSignal.timeout(8000)
      }).then((response) => (response.json()))
        .then((body) => {
          // Check for errors
          console.log("Next line is the body");
          console.log(body);
          if(!body.dataSaved){
            this.ErrorMessage = "There was an error updating the profile please contact us.";
            this.showErrorMessage = true;
          }
          
        }).catch(error => {
          // Handle network or other error
          this.ErrorMessage = "There was an error updating the profile (gen)";
          this.showErrorMessage = true;
          console.log(error);

        });

    }
  },

}
</script>


<template>
  <main>
    <Loading v-if="this.loadingData" />
    <!-- Navigation elements -->
    <Navigation page="1" />
    <div class="threeColParent">
      <div class="col1">
        <!-- <VerticalBread page="1" /> -->
      </div>
      <div class="col2">
        <br />
        <h1>Your Profile</h1>
        <p>These fields have already been pre-populated with information provided by your Customer Success Manager.<br> Please notify them if anything appears to be inaccurate here. </p>
        
        <div class="label">
          <div class="twoColFlex">
            <input id="FirstName" type="text" placeholder="First Name" :value="FirstName" disabled>
            <input id="Surname" type="text" placeholder="Surname" :value="LastName" disabled>
          </div>
          <input id="jobTitle" type="text" placeholder="Job Title" :value="JobTitle" disabled>
        </div>

        <h3>Contact</h3>
        <div class="twoColFlex">
          <input id="Email" type="text" placeholder="Email" :value="Email" disabled>
          <input id="Mobile" type="text" placeholder="Mobile" :value="MobilePhone" disabled>
        </div>

        <h1>Company</h1>
        <div class="twoColFlex">
          <input id="Company" type="text" placeholder="Company" :value="CompanyName" disabled>
        </div>

        <p class="companyRoleDetails">
          No business is the same. We appreciate that every organisation has
          a unique blend of people, processes and technologies. Please provide
          details of your role, responsibilities and where your department
          sits within the overall structure of your organisation.
        </p>

        <textarea name="" id="" cols="30" rows="10" v-model="KeyStrategicObjectives" placeholder="Enter at least 250 characters" maxlength="19000"></textarea>
        <br />
        <h1>Yearly Company Spend</h1>

        <h3>What's your yearly budget spend?</h3>

        <Slider pageName="yearlySpend" />

        <div class="twoColButtons">
          <button class="brandButton" @click="goHome()">Back</button>
          <button class="brandButtonLight" @click="goToBusinessFocus()">Save And Continue</button>
        </div>


      </div>
      <div class="col3">
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>


.twoColButtons {
  display: flex;

  button {
    width: 100%;
  }

  button:nth-child(1) {
    margin-right: 20px;
    ;
  }
}

.threeColParent {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  height: 100%;
  margin: 60px 20px 0 20px;
}


.col1 {


  @media screen and (max-width : 900px) {
    width: 1%;
    margin-right: 30px;
  }
}

.col2 {

  p.companyRoleDetails {
    margin-bottom: 20px;
    ;
  }

  width: 41.6%;

  @media screen and (max-width : 900px) {
    width: 100%;
  }


}

.col3 {
  width: 41.6%;

  @media screen and (max-width : 900px) {
    width: 10%;
  }

  p {
    width: 400px;

  }


}

.loginInput {
  display: flex;
  flex-flow: column;
  align-items: left;
  justify-content: center;

  .label {
    display: inline-block;
  }
}




.logo {
  margin-right: 20%;
}</style>
