<script setup>


</script>

<script>

export default {
    data() {
        return {
            lastSelectedButton: "",
            technology: false,
            data: false,
            culture: false,
            process: false,
            twoFocusAreasSelected: false,
            numberOfButtonsSelected: "",
            focusArea1: [],
            focusArea2: [],
            focusArea3: [],
            focusArea4: [],
            technologyTabSelected: false,
            dataTabSelected: false,
            cultureTabSelected: false,
            processTabSelected: false,
        }
    },
    // `mounted` is a lifecycle hook that can be called after the view has been rendered
    mounted() {
        let serverData = JSON.parse(window.localStorage.getItem("SFData"));
        this.focusArea1 = serverData.FocusArea1.split(";");
        this.focusArea2 = serverData.FocusArea2.split(";");
        this.focusArea3 = serverData.FocusArea3.split(";");
        this.focusArea4 = serverData.FocusArea4.split(";");

    },
    methods: {
        selectTwoButtonsOnly(event) {


            const myArray = [this.technology, this.data, this.culture, this.process];



            if (event === "technology") {
                this.technology = !this.technology;
                this.technologyTabSelected = !this.technologyTabSelected;
            }
            if (event === "data") {
                this.data = !this.data;
                this.dataTabSelected = !this.dataTabSelected;
            }
            
            if (event === "culture") {
                this.culture = !this.culture;
                this.cultureTabSelected = !this.cultureTabSelected;
            }
            if (event === "process") {
                this.process = !this.process;
                this.processTabSelected = !this.processTabSelected;
            }


            // Second button has been selected, now does the selected button need to be toggled?
            if (countTrue(myArray) === 2) {
                if (event === "technology" & this.technology === true) {

                    this.technology = !this.technology;
                }
                if (event === "data" & this.data === true) {
                    this.data = !this.data;
                }
                if (event === "culture" & this.culture === true) {
                    this.culture = !this.culture;
                }
                if (event === "process" & this.process === true) {
                    this.process = !this.process;
                }
                if (this.countTrue() === 2 & event != this.lastSelectedButton) {
                    // console.log("Current button " + event);
                    // console.log("Last selected button " + this.lastSelectedButton);
                    if (event === "technology") {
                        this.technology = true;
                    }
                    if (event === "data") {
                        this.data = true;
                    }
                    if (event === "culture") {
                        this.culture = true;
                    }
                    if (event === "process") {
                        this.process = true;
                    }
                    if (this.lastSelectedButton === "technology") {
                        this.technology = !this.technology;
                        this.technologyTabSelected = !this.technologyTabSelected;
                    }
                    if (this.lastSelectedButton === "data") {
                        this.data = !this.data;
                        this.dataTabSelected = !this.dataTabSelected;
                    }
                    if (this.lastSelectedButton === "culture") {
                        this.culture = !this.culture;
                        this.cultureTabSelected = !this.cultureTabSelected;
                    }
                    if (this.lastSelectedButton === "process") {
                        this.process = !this.process;
                        this.processTabSelected = !this.processTabSelected;
                    }
                }
            }

            // If two buttons have been selected and the user tried to add a third then toggle the last selected button off
            // and enable the currently selected button 

            // Set the currently selected button as the "last selected button"
            this.lastSelectedButton = event

            // This is a helper function that is used to check how many tabs have been selected as we only want 2 at the most to be selected
            function countTrue(arr) {
                let count = 0;
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i]) {
                        count++;
                    }
                }
                return count--;
            }



        },
        countTrue() {
            const myArray = [this.technology, this.data, this.culture, this.process];
            let count = 0;
            for (let i = 0; i < myArray.length; i++) {
                if (myArray[i]) {
                    count++;
                }
            }
            return count--;
        },
         toggleSubCategoriesAndStore(event) {

            // Check if 15 nodes have been selected prevent any new ones being added
            if (document.querySelectorAll(".subCategories.active").length > 11) {
                if (event.target.classList.contains("active") & event.target.classList.contains("subCategories")) {
                    event.target.classList.remove("active")
                }
            } else {
                if (event.target.classList.contains("active") & event.target.classList.contains("subCategories")) {
                    event.target.classList.remove("active")
                } else {
                    event.target.classList.add("active")
                }

            }


            // // Assign bool to which tabs selected
            // if (whichButtontoggled === "technology") {
            //     this.technologyTabSelected = !this.technologyTabSelected;
            // }
            // if (whichButtontoggled === "data") {
            //     this.dataTabSelected = !this.dataTabSelected;
            // }
            // if (whichButtontoggled === "culture") {
            //     this.cultureTabSelected = !this.cultureTabSelected;
            // }
            // if (whichButtontoggled === "process") {
            //     this.processTabSelected = !this.processTabSelected;
            // }


            // Keep track of which tab has been selected and then store it in local storage(technology, data, culture, process)
            // this.technologyTabSelected = document.querySelector(".singleColFlex.technology .brandButton.active ") !== null;
            // this.dataTabSelected = document.querySelector(".singleColFlex.data .brandButton.active ") !== null;
            // this.cultureTabSelected = document.querySelector(".singleColFlex.culture .brandButton.active ") !== null;
            // this.processTabSelected = document.querySelector(".singleColFlex.process .brandButton.active ") !== null;
            let sectedTabsData = {
                "technologyTabSelected": this.technologyTabSelected,
                "dataTabSelected": this.dataTabSelected,
                "cultureTabSelected": this.cultureTabSelected,
                "processTabSelected": this.processTabSelected,
            }



            window.localStorage.setItem("focusAreasSelected", JSON.stringify(sectedTabsData));
        }
    }
}
</script>



<template>
    <main>
        <h3 v-if="countTrue() < 2">Please select two primary focus areas related to your company.</h3>
        <h3 v-if="countTrue() > 1">Please select between 5 - 12 key focus areas.</h3>

        <div class="twoColFlex">
            <div class="singleColFlex technology" :class="{ singleColFlexSelected: technology }"
                @click="toggleSubCategoriesAndStore($event, 'technology')">
                <div class="brandButton tabHeader active" @click="selectTwoButtonsOnly('technology');">
                    Technology</div>
                <div class="selectFocusAreas" v-if="technology" :class="{ animateHeight: technology }">
                    <div v-for="(item) in focusArea1" class="subCategories displayRoman" :key="item">
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="singleColFlex data" :class="{ singleColFlexSelected: data }"
                @click="toggleSubCategoriesAndStore($event, 'data')">
                <div class="brandButton tabHeader active" @click="selectTwoButtonsOnly('data');">Data</div>
                <div class="selectFocusAreas" v-if="data" :class="{ animateHeight: data }">
                    <div v-for="(item) in focusArea2" class="subCategories displayRoman" :key="item">
                        {{ item }}  
                    </div>
                </div>
            </div>

            <div class="singleColFlex culture" :class="{ singleColFlexSelected: culture }"
                @click="toggleSubCategoriesAndStore($event, 'culture')">
                <div class="brandButton tabHeader active" @click="selectTwoButtonsOnly('culture');">Culture</div>
                <div class="selectFocusAreas" v-if="culture" :class="{ animateHeight: culture }">
                    <div v-for="(item) in focusArea3" class="subCategories displayRoman" :key="item">
                        {{ item }}  
                    </div>
                </div>
            </div>

            <div class="singleColFlex process" :class="{ singleColFlexSelected: process }"
                @click="toggleSubCategoriesAndStore($event, 'process')">
                <div class="brandButton tabHeader active" @click="selectTwoButtonsOnly('process');">Process</div>
                <div class="selectFocusAreas" v-if="process" :class="{ animateHeight: process }">
                    <div v-for="(item) in focusArea4" class="subCategories displayRoman" :key="item">
                        {{ item }}  
                    </div>
                </div>
            </div>


        </div>
    </main>
</template>

<style lang="scss" scoped>
.twoColFlex {
    margin-top: 30px;
}

.twoColButtons a {
    width: 23.5%;
}

// Animation
/* The animation code */
@keyframes animateHeightOpen {
    from {
        height: 0%;
    }

    to {
        height: 100%;
    }
}

/* The element to apply the animation to */
.animateHeight {
    height: 100%;
    overflow: hidden;
    animation-name: animateHeightOpen;
    animation-duration: 0.5s;
}
</style>



