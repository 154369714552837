import { render, staticRenderFns } from "./Completed-View.vue?vue&type=template&id=188a7845&scoped=true&"
import script from "./Completed-View.vue?vue&type=script&setup=true&lang=js&"
export * from "./Completed-View.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Completed-View.vue?vue&type=style&index=0&id=188a7845&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188a7845",
  null
  
)

export default component.exports