<script setup>
// import VerticalBread from '../components/VerticalBread.vue'
import ToastComponent from '../components/Toast-Component.vue'

</script>

<script>
import config from '../config.json'
export default {

  data() {

    return {
      envConfig: config,
      email: "",
      eppOrderNumber: "",
      ErrorMessage: "There was an error",
      showErrorMessage: false
    }
  },
  mounted() {
    // Reset EPPI 
    window.localStorage.setItem("EPPID", "none");
    // If the value of $10,000 changes it will need to also be updated in Slider.vue
    let projectOneData = {
      "projectName": "",
      "description": "",
      "focusAreas": "",
      "focusAreasSelected": false,
      "challenge1": "",
      "challenge2": "",
      "challenge3": "",
      "months": 2,
      "currentStage": "",
      "projectInvestment": "$10,000",
      "yourRole": "",
    }
    // if(window.localStorage.getItem("currentViewName") === null){
    // Initialize the project one,two,three local storage with an empty json object
    window.localStorage.setItem("projectOneData", JSON.stringify(projectOneData));
    window.localStorage.setItem("projectTwoData", JSON.stringify(projectOneData));
    window.localStorage.setItem("projectThreeData", JSON.stringify(projectOneData));
    // Set the current page view to = login
    window.localStorage.setItem("currentViewName", "Login")
    // }

    // Set current view to login page

  },
  methods: {
    checkLogin() {
      // Store EPP ID
      window.localStorage.setItem("EPPID", JSON.stringify(this.eppOrderNumber));
      this.showErrorMessage = false;

      let endpointUrl = ''; // Set the localhost and development settings in config.json

      if (this.envConfig.environment === "production") {
        endpointUrl = this.envConfig.productionURL + "login"
      } if (this.envConfig.environment === "development") {
        endpointUrl = this.envConfig.developmentURL + "login"
      }
      if (this.envConfig.environment === "local") {
        endpointUrl = this.envConfig.localDev + "login"
      }


      const controller = new AbortController();
      const signal = controller.signal;

      // Set a timeout of 5 seconds
      const timeout = setTimeout(() => {
        controller.abort();
        console.log("Error: Request timed out");
      }, 5000);
      

      fetch(endpointUrl, {
        method: 'POST', // Use POST method to send data
        body: JSON.stringify({ email: this.email, dynamicID: this.eppOrderNumber }), // Convert input values to JSON format
        headers: {
          'Content-Type': 'application/json' // Set request header to specify JSON format
        },
        signal: signal
        // signal: AbortSignal.timeout(8000)
      }).then((response) => (response.json()))
        .then((body) => {
          clearTimeout(timeout);
          if(body.error == "You sent too many requests. Please wait a while then try again"){
            this.ErrorMessage = "You are sending too many requests to the server. Please wait.";
            this.showErrorMessage = true;
          }
          // Check for errors
          if (body.error === "The email does match the EPP record") {
            this.ErrorMessage = "The Email or Password was not reognized";
            this.showErrorMessage = true;
            //
          } else if (body.info && body.info.records && body.info.records.length > 0){
            // The data returned
            // Executive projects profile

            // Create new object to store in local storage
            let SFData = {
              "AccountName": body.info.records[0].Account__r.Name,
              "FirstName": body.info.records[0].Contact__r.FirstName,
              "LastName": body.info.records[0].Contact__r.LastName,
              "JobTitle": body.info.records[0].Contact__r.Title,
              "Email": body.info.records[0].Contact__r.Email,
              "MobilePhone": body.info.records[0].Contact__r.MobilePhone,
              "FocusArea1": body.info.records[0].Focus_Area_1_Event__c,
              "FocusArea2": body.info.records[0].Focus_Area_2_Event__c,
              "FocusArea3": body.info.records[0].Focus_Area_3_Event__c,
              "FocusArea4": body.info.records[0].Focus_Area_4_Event__c,
              "EPPProfileName": body.info.records[0].Executive_Projects_Profile_Name__c,
              "Project1ID": body.info.records[0].Project_1_Id__c,
              "Project2ID": body.info.records[0].Project_2_Id__c,
              "Project3ID": body.info.records[0].Project_3_Id__c,
              "KeyStrategicObjectives": body.info.records[0].Key_Strategic_Objectives__c,

            };
            // Assign object to local storage item
            window.localStorage.setItem("SFData", JSON.stringify(SFData));
            // console.log(JSON.stringify(SFData))
            // console.log(SFData)

            // Name
            if (body.info.records[0].Account__c === undefined || body.info.records[0].Account__c === null) {
              console.log(body);
            } else {
              console.log(body.info.records[0].Account__c);
            }
            // If login info ok push user to next page
            this.$router.push("Profile").catch(() => { });

          }
        }).catch((error) => {
          clearTimeout(timeout); // Clear the timeout if an error occurs before the response is received
          console.log("Error: " + error);
          this.ErrorMessage = "There was a problem connecting to the server";
          this.showErrorMessage = true;
        });

    }
  },
}
</script>


<template>
  <main class="login">

    <ToastComponent class="movedLeft" :class="{ showErrorMessage, showErrorMessage }" :toastMessage="ErrorMessage" />

    <div class="loginMain">

      <div class="login1">
        <!-- <VerticalBread page="0" /> -->
      </div>
      <div class="login2">

        <img alt="Vue logo" class="logo" src="@/assets/GDS_logo-dark.svg" />

      </div>
      <div class="login3">
        <div class="loginContainer">
          <div class="loginIntro">
            <img alt="Vue logo" class="logo" src="@/assets/GDS_logo-dark.svg" />
            <h1>Welcome</h1>
            <p>
              Please login with the credentials sent to you by your Customer Success Manager.
            </p>
          </div>

          <div class="loginInput">
            <div class="label">
              <div class="loginText">Email</div>
              <input :value="email" @input="event => email = event.target.value" id="email" type="text" placeholder="" name="username" autocomplete="username" required>
            </div>
            <div class="label">
              <div class="loginText">Access Code</div>
              <input :value="eppOrderNumber" @input="event => eppOrderNumber = event.target.value" id="EPP" type="password" name="password" autocomplete="current-password" required>
            </div>
            <span class="tinyText"><a href="#">Forgotten Email / Access code</a></span>
            <!-- <RouterLink to="/Welcome">
              <button class="loginButton brandButton" @click="increment">Login</button>
            </RouterLink> -->
            <button class="brandButtonLight label" @click="checkLogin">Login</button>

          </div>
        </div>

      </div>
    </div>
  </main>
</template>


<style lang="scss" scoped>
.login3 .logo {
  display: none;
  width: 100%;

  @media (max-width: 1024px) {
    display: block;
  }
}

.logo {
  width: 88%;
  margin-right: 60px;

  @media (max-width: 1024px) {
    display: none;
  }
}

.loginMain {
  display: flex;
  height: 100vh;

  .brandButtonLight {
    width: 200px;
  }
}

.login1 {
  width: 26%;
}

.login2 {
  width: 30%;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login3 {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tinyText {
  display: block;
}

@media (min-width: 1250px) {
  .login2 {
    width: 20%;
  }

  .login3 {
    width: 25%;
  }
}

@media (max-width: 1024px) {
  .login2 {
    width: 1%;
  }

  .loginIntro img.logo {
    width: 100px;
  }
}

@media (max-width: 600px) {
  .login1 {
    display: none;
  }

  .login2 {
    width: 1%;
  }

  .login3 {
    width: 80%;
  }

  .login3 .logo {
    width: 100px;
    margin: 0 auto;
  }
}
</style>