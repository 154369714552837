<script setup>

</script>

<script>
export default {
    mounted() {
        // add hidden to all 
        let technologyList = document.querySelectorAll(".technology .selectFocusAreas .subCategories")

        technologyList.forEach(function (item) {
            item.classList.add("hidden")
        })


        // Remove hidden
        JSON.parse(window.localStorage.getItem("businessFocusAreas")).techValues.split(",").forEach(function (item) {

            technologyList.forEach(function (techItem) {
                if (item.length > 0) {

                    if (item == techItem.textContent) {
                        console.log(item)
                        console.log(techItem)
                        techItem.classList.remove("hidden")
                    }

                }

            })



        })

    },
    props: [
        'page'
    ],
}
</script>

<template>
    <div class="hidden">
    </div>
</template>

  
<style scoped lang="scss"></style>
  