<script setup>
import "../assets/sass/main.scss"
// import "../assets/sass/slider.scss"
</script>

<script>

export default {
  name: 'SliderMonth',
  props: ['pageName'],
  data() {
    return {
      ProjectOneData: "",
    }
  },

  mounted() {

    let projectOneData = JSON.parse(window.localStorage.getItem("projectOneData"));
    this.ProjectOneData = projectOneData;



    this.startSlider()



  },
  methods: {
    saveMonthlyValue(val) {
      this.ProjectOneData.months = val;
      // if on yearly spend view
      if (this.pageName === "yearlySpend") {
        window.localStorage.setItem("yearlySpend", val);
      }
      // Project One months to complete
      if (this.pageName === "firstProjectMonth") {
        let tempProjectOneData = JSON.parse(window.localStorage.getItem("projectOneData"));
        tempProjectOneData.months = val
        window.localStorage.setItem("projectOneData", JSON.stringify(tempProjectOneData));

      }
      if (this.pageName === "secondProjectMonth") {
        let tempProjectTwoData = JSON.parse(window.localStorage.getItem("projectTwoData"));
        tempProjectTwoData.months = val
        window.localStorage.setItem("projectTwoData", JSON.stringify(tempProjectTwoData));
      }
      if (this.pageName === "thirdProjectMonth") {
        let tempProjectThreeData = JSON.parse(window.localStorage.getItem("projectThreeData"));
        tempProjectThreeData.months = val
        window.localStorage.setItem("projectThreeData", JSON.stringify(tempProjectThreeData));
      }

    },
    startSlider() {
      const slider2 = document.getElementById("monthRange");
      const handle = document.getElementById("monthInput");
      handle.value = this.ProjectOneData.months + " Months"
      const segmentSize = 1; // in percentage points
      handle.style.left = this.ProjectOneData.months / 24 * 100 + "%";
      // Store slider values on input change
      let tempThis = this;
      slider2.oninput = function () {
        const roundedValue = Math.round(this.value / segmentSize) * segmentSize;

        handle.style.left = roundedValue * 4 + "%";
        if (this.value <= 2) {
          handle.value = Number(roundedValue).toLocaleString() + " Month";
          tempThis.saveMonthlyValue(roundedValue)
        } else {
          handle.value = Number(roundedValue).toLocaleString() + " Months";
          tempThis.saveMonthlyValue(roundedValue)
        }

      }
    }
  }
}
</script>

<template>
  <div class="slidecontainer">
    <div class="sliderMonths">
      <input v-model="ProjectOneData.months" type="range" min="1" max="24" class="slider" id="monthRange" style="accent-color: #FF6606">
      <input class="" id="monthInput" type="text" placeholder="" value="3 Months" style="pointer-events: none;">
    </div>
  </div>
</template>

<style lang="scss" scoped>
// css in slider.scss
input#monthInput {
    width: 20%;
    text-align: center;
    top: -100px;

}
</style>