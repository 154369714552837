<script setup>
// import VerticalBread from '../components/VerticalBread.vue'
import Navigation from '../components/Navigation-Component.vue'
import FocusAreasSelect from '../components/FocusAreasSelect.vue'
import ToastComponent from '../components/Toast-Component.vue'
import Loading from '../components/LoadingComp.vue'

// document.querySelector(".selectCallToAction").addEventListener('click', function(event) {
 
//  document.querySelector(".hiddenSteps").classList.remove("hidden")
// })
</script>

<script>

export default {
  data() {
    return {
      lastSelectedButton: "",
      technology: false,
      data: false,
      culture: false,
      process: false,
      twoFocusAreasSelected: false,
      numberOfButtonsSelected: "",
      technologyValuesSelected: "",
      dataValuesSelected: "",
      cultureValuesSelected: "",
      processValuesSelected: "",
      showErrorMessage: "false",
      ErrorMessage: "There was a problem",
      loadingData: false      
    }
  },

  // `mounted` is a lifecycle hook which we will explain later
  mounted() {
    this.showErrorMessage = false;
    // TODO setup bi-directional comms to child component instead of setInterval
    setInterval(() => {
      this.numberOfButtonsSelected = document.querySelectorAll(".twoColFlex .active").length;
    }, 1000);
  },
  methods: {
    whatHasBeenSelected(){
      this.loadingData = true;
      this.technologyValuesSelected = this.getSelectedFromCol(".technology .selectFocusAreas .active")
      this.dataValuesSelected = this.getSelectedFromCol(".data .selectFocusAreas .active")
      this.cultureValuesSelected = this.getSelectedFromCol(".culture .selectFocusAreas .active")
      this.processValuesSelected = this.getSelectedFromCol(".process .selectFocusAreas .active")

      let businessFocusAreas = { 
          EPPID: JSON.parse(window.localStorage.getItem("EPPID")), 
          techValues: this.technologyValuesSelected,
          dataValues: this.dataValuesSelected,
          cultureValues: this.cultureValuesSelected,
          processValues: this.processValuesSelected,
         }

         window.localStorage.setItem("businessFocusAreas", JSON.stringify(businessFocusAreas))
        //  // Get the current browser location
        // var currentLocation = window.location.href;
        // // Remove everything after the last forward slash '/'
        // currentLocation = currentLocation.substring(0, currentLocation.lastIndexOf('/'));
        // // Set the new location
        // window.location.href = currentLocation+"/AddFirstProject";
    },
    getSelectedFromCol(selector){
      let itemsList = "";
      let focusAreasArray = document.querySelectorAll(selector)
      focusAreasArray.forEach(element => {
        itemsList = itemsList + element.innerHTML + ",";
      });
      return itemsList;
    },
    updateValues(){
      // const endpointUrl = 'http://localhost:8000/businessFocus'; // Replace with the actual endpoint URL on server
      // fetch(endpointUrl, {
      //   method: 'POST', // Use POST method to send data
      //   body: JSON.stringify({ 
      //     EPPID: JSON.parse(window.localStorage.getItem("EPPID")), 
      //     techValues: this.technologyValuesSelected,
      //     dataValues: this.dataValuesSelected,
      //     cultureValues: this.cultureValuesSelected,
      //     processValues: this.processValuesSelected,
      //    }), // Convert input values to JSON format
      //   headers: {
      //     'Content-Type': 'application/json' // Set request header to specify JSON format
      //   },
      //   // signal: AbortSignal.timeout(8000)
      // }).then((response) => (response.json()))
      //   .then((body) => {
      //     // console.log(body.info.records[0]);
      //     // Check for errors
      //     console.log("Next line is the body");
      //     console.log(body);
          
      //     if (body.info[0].errors[0] !== undefined) {

      //       this.ErrorMessage = "There was an error sending this selection";
      //       this.showErrorMessage = true;
      //       this.loadingData = true;
      //       //
      //     }else {

      //       // If login info ok push user to next page
      //       this.$router.push("AddFirstProject").catch(() => { });

      //     }

          
      //   }).catch(error => {
      //     // Handle network or other error
      //     this.ErrorMessage = "There was an error on the network the data has not been sent";
      //     this.showErrorMessage = true;
      //     this.loadingData = true;
      //     console.log(error);

      //   });
    }
  },
  computed: {
    // Realtime updates computed example
    showTheSaveAndContinueButton() {
      return 5 * 5
    }
  }

}
</script>



<template>
  <main>
    <Navigation page="2" />
    <ToastComponent class="movedLeft" :class="{ showErrorMessage, showErrorMessage }" :toastMessage="ErrorMessage" />
    <Loading v-if="this.loadingData" />
    <div class="threeColParent">
      
      <div class="col1">
        <!-- <VerticalBread page="2" /> -->
      </div>
      <div class="col2">
        <!-- Navigation elements -->
        <br />
        <h1>Business Focus Areas </h1>
        <!-- Take the prop being sent to BusinessFocus and send it to this child componnt -->
        <FocusAreasSelect/>
    
        
        <div class="twoColButtons">
          
          <router-link to="/AddFirstProject">
            <div v-if="numberOfButtonsSelected > 5" class="brandButtonLight" @click="whatHasBeenSelected()">Save & Continue</div>
          </router-link>
            

    
        </div>

      </div>
      <div class="col3">
      </div>
    </div>
  </main>


  

</template>

<style lang="scss" scoped>

h1 {
  margin-bottom: 50px;
}
.twoColButtons div {
    width: 100%;
    margin-right:10px;
}
.col2 {
  width:100%;
}

.logo {
  margin-right: 20%;
}




// Animation
/* The animation code */
@keyframes animateHeightOpen {
  from {height: 0%;}
  to {height: 100%;}
}

/* The element to apply the animation to */
.animateHeight {
  height: 100%;
  overflow: hidden;
  animation-name: animateHeightOpen;
  animation-duration: 0.5s;
}





// Error message animation
.movedLeft {
  position: relative;
  left: -400%;
}

.showErrorMessage {
  left: -100%;
  position: absolute;
  animation-name: run;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  background-color: #FF6606;
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  z-index: 3;
}


@keyframes run {
  0% {
    left: -60%;
  }

  30% {
    left: 10%;
  }

  100% {
    visibility: hidden;
    left: 10%;
  }

}


</style>



