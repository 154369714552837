<script setup>
import "../assets/sass/main.scss"
// import "../assets/sass/slider.scss"
</script>

<script>

export default {
  name: 'SliderBudget',
  props: ['pageName'],
  data() {
    return {
      yearlySpend: "",
      budgetRange: 10000
    }
  },

  mounted() {

    let temp = window.localStorage.getItem("yearlySpend");

    if (temp === null) {
      this.yearlySpend = " 480000";
    } else {
      this.yearlySpend = temp;
    }

    this.startSlider()

  },
  methods: {
    saveValue(val) {

      this.yearlySpend = val;
      // if on yearly spend view
      if (this.pageName === "yearlySpend") {
        window.localStorage.setItem("yearlySpend", val);
      }
      // Project One months to complete
      if (this.pageName === "firstProjectMonth") {
        let tempProjectOneData = JSON.parse(window.localStorage.getItem("projectOneData"));
        window.localStorage.setItem("projectOneData", JSON.stringify(tempProjectOneData));

      }
      // Project One investment
      if (this.pageName === "firstProjectInvestment") {
        let tempProjectOneData = JSON.parse(window.localStorage.getItem("projectOneData"));
        tempProjectOneData.projectInvestment = val
        window.localStorage.setItem("projectOneData", JSON.stringify(tempProjectOneData));

      }
      // Project Two investment
      if (this.pageName === "secondProjectInvestment") {
        let tempProjectOneData = JSON.parse(window.localStorage.getItem("projectTwoData"));
        tempProjectOneData.projectInvestment = val
        window.localStorage.setItem("projectTwoData", JSON.stringify(tempProjectOneData));
      }


      // Project Three investment
      if (this.pageName === "thirdProjectInvestment") {
        let tempProjectOneData = JSON.parse(window.localStorage.getItem("projectThreeData"));
        tempProjectOneData.projectInvestment = val
        window.localStorage.setItem("projectThreeData", JSON.stringify(tempProjectOneData));
      }

    },
    startSlider() {
      
      let investmentAddedYet = JSON.parse(window.localStorage.getItem("projectOneData"))
      if(investmentAddedYet.projectInvestment === "$10,000"){
        this.yearlySpend = 0;
      }
      
      let budgetRange = 10000000;
      if (this.pageName === "yearlySpend") {
        budgetRange = 100000000
      }
      const slider2 = document.getElementById("Budget");
      const handle = document.getElementById("budgetInput");
      const segmentSize = 100000; // in percentage points
      handle.value = "$" + this.yearlySpend;
      handle.style.left = this.yearlySpend / budgetRange * 100 + "%";

      // Store slider values on input change
      let tempThis = this;
      slider2.oninput = function () {
        const roundedValue = Math.round(this.value / segmentSize) * segmentSize;

        handle.style.left = (roundedValue / budgetRange * 100) + "%";
        handle.value = "$" + Number(roundedValue).toLocaleString();
        tempThis.saveValue(roundedValue)

      }


    }
  }
}
</script>

<template>
  <div class="slidecontainer">
    <div class="twoColSlider" v-if="pageName === 'yearlySpend'">
      <input v-model="yearlySpend" type="range" min="1" max="100000000" class="slider" id="Budget"
        style="accent-color: #FF6606">
      <input class="" id="budgetInput" type="text" placeholder="" value="">
    </div>
    <div class="twoColSlider" v-if="pageName === 'firstProjectInvestment'">
      <input v-model="yearlySpend" type="range" min="1" max="100000000" class="slider" id="Budget"
        style="accent-color: #FF6606">
      <input class="" id="budgetInput" type="text" placeholder="" value="">
    </div>
    <div class="twoColSlider" v-if="pageName === 'secondProjectInvestment'">
      <input v-model="yearlySpend" type="range" min="1" max="100000000" class="slider" id="Budget"
        style="accent-color: #FF6606">
      <input class="" id="budgetInput" type="text" placeholder="" value="">
    </div>
    <div class="twoColSlider" v-if="pageName === 'thirdProjectInvestment'">
      <input v-model="yearlySpend" type="range" min="1" max="100000000" class="slider" id="Budget"
        style="accent-color: #FF6606">
      <input class="" id="budgetInput" type="text" placeholder="" value="">
    </div>
  </div>
</template>

<style lang="scss" scoped>
// css in slider.scss

input#budgetInput {
  width: 130px;
  text-align: center;
  pointer-events: none;
}
</style>