<script setup>
// import VerticalBread from '../components/VerticalBread.vue'
import Navigation from '../components/Navigation-Component.vue'

</script>
<script>
export default {
  data() {

    return {
      FirstName: "",
    }
  },

  // `mounted` is a lifecycle hook which we will explain later
  mounted() {
    // let serverData = JSON.parse(window.localStorage.getItem("SFData"));
  },
  methods: {
    test() {

    }
  },

}
</script>


<template>
  <main>
    <!-- Navigation elements -->
    <Navigation page="6" />
    <div class="threeColParent">

      <div class="col1">
        <!-- <VerticalBread page="8" /> -->
      </div>
      <div class="col2">

        <!-- <img alt="GDS Logo Orange" class="logoSideOrange" src="@/assets/logo-orange-side.png" /> -->
        <br />
        <h1>Your profile has been submitted, your CSM will be in touch to discuss next steps. </h1>
        <p class="tinytext">You can now navigate away from this page.</p>

        <p class="bold underline">
          Next steps
        </p>
        <div class="flexNextSteps">
          <div class="nextCircle">
            <p>Profile submtted</p>
          </div>
          <div class="nextCircle">
            <p>Profile approved</p>
          </div>
          <div class="nextCircle">
            <p>Meeting selections available</p>
          </div>
          <div class="nextCircle">
            <p>Tailored agenda released</p>
          </div>
        </div>


      </div>
      <div class="col3">
      </div>
    </div>
    <footer></footer>
  </main>
</template>

<style lang="scss" scoped>
.twoColButtons {
  display: flex;

  a {
    width: 100%;
  }

  a:nth-child(1) {
    margin-right: 20px;
    ;
  }
}

.threeColParent {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  height: 100%;
  margin: 60px 20px 0 20px;

  .col1 {
    width: 25%;
  }

  .col2 {
    width: 50%;

    h1 {
      width: 100%;
    }

    p.companyRoleDetails {
      margin-bottom: 20px;
      ;
    }
  }

  .col3 {
    width: 25%;

    p {
      width: 400px;
    }
  }
}



.loginInput {
  display: flex;
  flex-flow: column;
  align-items: left;
  justify-content: center;

  .label {
    display: inline-block;
  }
}




.logo {
  margin-right: 20%;
}



// Next steps
.bold.underline {
  margin-top: 90px;
}

.flexNextSteps div {
  width: 30%;

}

.flexNextSteps {
  width: 100%;
}

.nextCircle {
  border-top: 2px solid #dedede;

  p {
    margin-top: 20px;
  }
}

.nextCircle:before {
  content: "";
  position: absolute;
  transform: translate(0%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #d2d2d2;
}

.nextCircle:nth-child(1):before {
  background: #fa7026;
}

.flexNextSteps .nextCircle:nth-child(4):before {
  content: "";
  transform: translate(-30%, -50%);
  height: 20px;
  border-radius: 50%;
  background: #d2d2d2;
}


// Remove the border on the last element
.nextCircle:nth-child(4) {
  border: none;
}

@media screen and (max-width: 1280px) {
  h1 {
    font-size: 25px;
    line-height: 30px;
  }


  .flexNextSteps {
    display: flex;
    flex-direction: column;
    align-items: center;

    .nextCircle {
      width: 80%;
      border: none;
    }
    .nextCircle:nth-child(4)::before {
      transform: translate(-150%, 100%);
    }
    .nextCircle::before {
      transform: translate(-150%, 100%);
    }
  }

  .threeColParent {
    margin: 60px 20px 0 20px;
    .col1 {
      width: 5%;
    }

    .col2 {
      width: 90%;

      h1 {
        width: 100%;
      }

      p.companyRoleDetails {
        margin-bottom: 20px;
        ;
      }
    }

    .col3 {
      width: 5%;

      p {
        width: 400px;
      }
    }
  }
}</style>
