<script setup>
// import VerticalBread from '../components/VerticalBread.vue'
import Navigation from '../components/Navigation-Component.vue'
import SliderMonth from '../components/SliderMonth.vue'
import Slider from '../components/Slider.vue'
import Loading from '../components/LoadingComp.vue'
import ToastComponent from '../components/Toast-Component.vue' // Error messages
import SaveAllData from '../components/SaveAllData.vue' // Error messages
</script>

<script>
import config from '../config.json';
export default {

  data() {

    return {
      envConfig: config,
      count: 1,
      hideElements: true,
      isHovering: false,
      challenges: 1,
      hoveringRFP: false,
      ProjectOneData: { "focusAreasSelected": false },
      currentPageName: "",
      showErrorMessage: false,
      ErrorMessage: "There was an error",
      loadingData: false,
      EPPID: ""
    }
  },
  created() {

  },
  // `mounted` is a lifecycle hook which we will explain later
  mounted() {
    // Assign the store data for project one to "ProjectOneData" to become reactive
    let projectOneData = JSON.parse(window.localStorage.getItem("projectOneData"));
    this.ProjectOneData = projectOneData;
 
    let EPPID = JSON.parse(window.localStorage.getItem("EPPID"));
    this.EPPID = EPPID;

    if (this.ProjectOneData.focusAreasSelected) {
      this.scrollDown()
    }


  },
  methods: {
    onlyOne(checkbox, sectionName) {

      if(sectionName === "currentStage") {
        let checkboxes = document.querySelectorAll(".currentStage .checkBoxRow input")
        checkboxes.forEach((item) => {
          if (item.name !== checkbox) {
            item.checked = false
          }

          if(item.checked) {
            this.ProjectOneData.currentStage = item.name
          }

        })
      }
      if(sectionName === "yourRole") {
        let checkboxes = document.querySelectorAll(".companyRoleInput  .checkBoxRow input")
        checkboxes.forEach((item) => {
          if (item.name !== checkbox) {
            item.checked = false
          }

          if(item.checked) {
            this.ProjectOneData.yourRole = item.name
          }

        })
      }
      // Update the projectData as the slider component sets this independently
      let tempProjectOneData = JSON.parse(window.localStorage.getItem("projectOneData"));
      this.ProjectOneData.projectInvestment = tempProjectOneData.projectInvestment;
      this.ProjectOneData.months = tempProjectOneData.months;
      this.saveData()

    },
    hasFocusAreasBeenAdded() {
      if(this.ProjectOneData.focusAreas != null) {
        this.scrollDown()
      }
    },
    clickedFocusAreasSelected(){
      // Project one go to focus areas
      window.localStorage.setItem("currentViewName", "ProjectOneFocusAreas")
      this.$router.push("FocusAreasProjectOne");
    },

    scrollDown() {
      var delayInMilliseconds = 500; 

      setTimeout(function () {
        document.querySelector(".afterFocusAreasFilledIn").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, delayInMilliseconds);
    },
    saveData() {
      window.localStorage.setItem("projectOneData", JSON.stringify(this.ProjectOneData));
    }
   
  },
  computed: {
    // Realtime updates
    ProjectOneName() {
      return this.ProjectOneData.projectName
    }
  }


}
</script>


<template>
  <main>
    <Navigation page="4" />
    <Loading v-if="(this.loadingData)" />
    <ToastComponent class="movedLeft" :class="{ showErrorMessage, showErrorMessage }" :toastMessage="ErrorMessage" />
    <div class="threeColParent">

      <div class="col1">
        <!-- <VerticalBread page="4" /> -->
      </div>
      <div class="col2">
        <br />
        <h1>{{ ProjectOneName }}</h1>
        <br />
        <p class="bold">What is the name of your first project?</p>
        <input v-model="ProjectOneData.projectName" @input="saveData()" class="" id="ProjectOneName" type="text" maxlength="254" placeholder="Project name">

        <p class="bold">Please provide a high level overview of this project</p>
        <textarea v-model="ProjectOneData.description" @input="saveData()" name="" id="ProjectOneDescription" cols="30" maxlength="19000" rows="10"
          placeholder="Example: The goal of the project is to enable predictive analytics approach in determining essentially three goals: predict new customer lifetime value (gross margin they will provide in the next 12 months); propensity scoring to churn in the next month; propensity scoring to being favourable to upselling/cross selling projects."></textarea>

        <p class="bold">Please select the relevant focus areas that align with this project</p>


        <button class="addChallenge" @click="clickedFocusAreasSelected()">
          <img v-if="!this.ProjectOneData.focusAreasSelected" alt="Add Focus Areas" class="plusCircle"
            src="@/assets/images/plus-circle.svg" />
          <span v-if="!this.ProjectOneData.focusAreasSelected">Choose focus areas</span>
          <img v-if="this.ProjectOneData.focusAreasSelected" alt="Completed" class="plusCircle"
            src="@/assets/images/check-circle.png" />
          <span v-if="this.ProjectOneData.focusAreasSelected">Completed</span>
        </button>

        <div class="afterFocusAreasFilledIn" v-if="this.ProjectOneData.focusAreasSelected">
          <p class="bold">
            Please share a challenge or obstacle that needs to be overcome to successfully complete this project.
          </p>
          <p>You can add up to 3 challenges.</p>

          <div class="challengeOne">
            <h3>
              *Challenge 1 
            </h3>
            
            <textarea v-model="ProjectOneData.challenge1" @input="saveData()" name="" id="ProjectOneDescription" cols="30" maxlength="254" rows="5" placeholder="Enter at least 25 characters"></textarea>
          </div>

          <div class="challengeTwo" v-if="challenges > 1">
            <h3>
              Challenge 2
            </h3>
            <textarea v-model="ProjectOneData.challenge2" @input="saveData()" name="" id="ProjectOneDescription" cols="30" maxlength="254" rows="5" placeholder="Enter at least 25 characters"></textarea>
          </div>

          <div class="challengeThree" v-if="challenges > 2">
            <h3>
              Challenge 3
            </h3>
            <textarea v-model="ProjectOneData.challenge3" @input="saveData()" name="" id="ProjectOneDescription" cols="30" maxlength="254" rows="5" placeholder="Enter at least 25 characters"></textarea>
          </div>

          <button class="addChallenge" @click="challenges++" v-if="challenges < 3">
            <img alt="Add Challenge" class="plusCircle plus" src="@/assets/images/plus-circle.svg" /><span>Add
              Challenge</span>
          </button>

          <p class="bold">When do you estimate this project will be completed?</p>
     <!-- Change pageName for each new project -->
          <SliderMonth pageName="firstProjectMonth" />

          <div class="currentStage">
            <p class="bold">What is the current stage of this project?</p>
            <div class="twoColInput">
              <div class="checkBoxRow">
                <input type="checkbox" name="Pre Planning" @click="onlyOne('Pre Planning', 'currentStage')">
                <p>Pre Planning</p>
              </div>
              <div class="checkBoxRow">
                <input type="checkbox" name="Planning Stages" @click="onlyOne('Planning Stages', 'currentStage')">
                <p>Planning Stages</p>
              </div>
            </div>
            <div class="twoColInput">
              <div class="checkBoxRow" @mouseover="hoveringRFP = true" @mouseleave="hoveringRFP = false">
                <input alt="Request for proposal / Request for information" type="checkbox" name="Request for proposal"
                  @click="onlyOne('Request for proposal', 'currentStage')">
                <p alt="Request for proposal / Request for information">RFP/RFI</p>
                <div :class="{rfiHint: hoveringRFP, hidden: !hoveringRFP}">
                  <p>Request For Proposal / Request For Information</p>
                </div>
              </div>
              <div class="checkBoxRow">
                <input type="checkbox" name="Vendor Selection" @click="onlyOne('Vendor Selection', 'currentStage')">
                <p>Vendor Selection</p>
              </div>
            </div>
          </div>

          <p class="bold">Select the total project investment (Aproximate)</p>
          <Slider pageName="firstProjectInvestment" />

          <div class="companyRoleInput">
            <p class="bold">What is your role within the company?</p>
            <div class="twoColInput">
              <div class="checkBoxRow">
                <input type="checkbox" name="Budget Holder" @click="onlyOne('Budget Holder', 'yourRole')">
                <p>Budget Holder</p>
              </div>
              <div class="checkBoxRow">
                <input type="checkbox" name="Strategic Owner" @click="onlyOne('Strategic Owner', 'yourRole')">
                <p>Strategic Owner</p>
              </div>
            </div>
            <div class="twoColInput">
              <div class="checkBoxRow">
                <input alt="Implementation Lead" type="checkbox" name="Implementation Lead"
                  @click="onlyOne('Implementation Lead', 'yourRole')">
                <p>Implementation Lead</p>
              </div>
              <div class="checkBoxRow">
                <input type="checkbox" name="Influencer" @click="onlyOne('Influencer', 'yourRole')">
                <p>Influencer</p>
              </div>
            </div>
          </div>

          <SaveAllData pageName="Project1"/>
        </div>


      </div>
      <div class="col3">
      </div>
    </div>
  </main>
</template>


<style lang="scss" scoped>
  // Custom CSS overides for this component only
  @import "../assets/sass/addProjects.scss";

</style>