<script setup>

</script>

<script>
export default {
    mounted() {

    },
    props: [
        'page'
    ],
}
</script>

<template>
    <div class="loadingComponent">
        <div class="loading">
            <img alt="Loading" class="loadingImage" src="@/assets/logo-orange-side.png" />
        </div>
    </div>
</template>

  
<style scoped lang="scss">
.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #000000a1;
    z-index: 10;
    top: 0;
    overflow: hidden;
    left: 0;

    img.loadingImage {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100px;
        animation-name: example;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}



@keyframes example {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
  