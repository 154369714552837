<script setup>

</script>


<script>
export default {
    props: [
        'toastMessage'
    ],
    data() {
        return {
            count: 0
        }
    },
    mounted() {
        // Always scroll the user to the top of the page
        window.scrollTo(0, 0);
        // Emits on mount

    },
    methods: {




        toast(toastClassName, toastMessage) {
        let toastMain = document.getElementsByClassName(toastClassName)[0];
        let toastContent = document.getElementsByClassName("toast-message")[0];

        toastContent.innerHTML = toastMessage;
        toastMain.classList.remove("toast-show");
        setTimeout(function () {
            toastMain.classList.add("toast-show");
        }, 500);
        toastMain.addEventListener("click", function () {
            toastMain.classList.remove("toast-show");
        });
    }

    }

}
</script>


<template>
    <div class="">{{ toastMessage }}</div>
</template>



<style lang="scss" scoped>


// Error message animation
.movedLeft {
  position: fixed;
  left: -100%;
}

.showErrorMessage {
    left: 50%;
    position: fixed;
    animation-name: run-48d28737;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    background-color: #FF6606;
    padding: 10px;
    border-radius: 3px;
    color: #fff;
    z-index: 28;
    top: 100px;
    display: block;
}


@keyframes run {
  0% {
    left: -60%;
  }

  30% {
    left: 10%;
  }

  100% {
    visibility: hidden;
    left: 10%;
  }

}
</style>